import React from "react"
import Quadrant from "../../components/AimBox/Quadrant/Quadrant"
import Layout from "../../components/Base/Layout"
import SEO from "../../components/Base/SEO"

export default function home() {
  return (
    <Layout>
      <SEO title={"Sheer Quadrant"} />
      <Quadrant />
    </Layout>
  )
}
